import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import common_no from "./locales/no/common_no.json";
import common_en from "./locales/en/common_en.json";


i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.s
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({ 
    fallbackLng: (code)=>{  
      if(!code){
        return ["no"];
      }
      const _code = code.toLowerCase();    
      if(["nb", "nn", "no"].includes(_code)){
        return ["no"];
      }

      // NO-nb, nb-NO osv
      if(["nb", "nn", "no"].includes(_code.substr(0,2))){
        return ["no"];
      }
      
      return ["en"];
    },
    /*fallbackLng: {
      'nb': ['no'],
      'nn': ['no'],
      default: ['en']
    },*/    
    
    debug: false,
    cleanCode: true, // changes first part of language code to lower-case
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    resources: {
      en: {common: common_en},
      no: {common: common_no}
    },

    react: { 
      useSuspense: false //   <---- this will do the magic
    },

  });


export default i18n;