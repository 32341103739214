import React, { useReducer } from "react";
import {Settings} from "../../config/Settings";
//import { ReducerAction } from "react";
//import { Reducer } from "react";





const initialState = {
    constants: {
        siteUrl: Settings.PAGE_URL,
        largeFontRootSize: "20px",
        zoneUuids: [...Settings.TIDSROM_ZONE_IDS]
    },
    data: {
        zones: [],
        settings: {},
        tabSettings: {},
        largeFont: false,
        outDatedBrowserWarning: true,
    },
    
}

export const Store: any = React.createContext(initialState);

interface valueType {
    state: State;
    dispatch: React.Dispatch<Action>;

}

interface State {
    constants: {
        siteUrl: string
        zoneUuids: string[]
    };
    data: {
        zones: object[]
        settings: object;
        tabSettings: object;
        largeFont: boolean;
        outDatedBrowserWarning: boolean;
    };

}

interface Action {
    type: "SET_DATA_ZONES" | "SET_DATA_SETTINGS" | "SET_DATA_TABSETTINGS" | "SET_DATA_LARGEFONT" | "SET_DATA_OUTDATEDBROWSERWARNING";
    payload: object[];
}


const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case "SET_DATA_ZONES":
            return { ...state, data: { ...state.data, zones: action.payload } };

        case "SET_DATA_SETTINGS":
            return { ...state, data: { ...state.data, settings: action.payload } };

        case "SET_DATA_TABSETTINGS":
            return { ...state, data: { ...state.data, tabSettings: action.payload } };

        case "SET_DATA_LARGEFONT":
            return { ...state, data: { ...state.data, largeFont: action.payload } };
        
        case "SET_DATA_OUTDATEDBROWSERWARNING":
            return { ...state, data: { ...state.data, outDatedBrowserWarning: action.payload } };
            
        default:
            return state;
    }
}

export const StoreProvider = props => {
    //@ts-ignore
    const [state, dispatch] = useReducer(reducer, initialState);

    const value: any = { state, dispatch };
    return (
        <Store.Provider value={value}>
            {props.children}
        </Store.Provider>)
}