module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tidsrom","short_name":"Tidsrom","start_url":"/index.html","background_color":"#ffffff","theme_color":"#ffffff","description":"Bli med på en tidsreise som viser veien mot et moderne Norge","display":"standalone","icon":"src/images/icons/android-chrome-512x512.png","icons":[{"purpose":"maskable","src":"icons/icon-maskable-48x48.png","sizes":"48x48","type":"image/png"},{"purpose":"maskable","src":"icons/icon-maskable-72x72.png","sizes":"72x72","type":"image/png"},{"purpose":"maskable","src":"icons/icon-maskable-96x96.png","sizes":"96x96","type":"image/png"},{"purpose":"maskable","src":"icons/icon-maskable-144x144.png","sizes":"144x144","type":"image/png"},{"purpose":"maskable","src":"icons/icon-maskable-192x192.png","sizes":"192x192","type":"image/png"},{"purpose":"maskable","src":"icons/icon-maskable-256x256.png","sizes":"256x256","type":"image/png"},{"purpose":"maskable","src":"icons/icon-maskable-384x384.png","sizes":"384x384","type":"image/png"},{"purpose":"maskable","src":"icons/icon-maskable-512x512.png","sizes":"512x512","type":"image/png"},{"purpose":"any","src":"icons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"purpose":"any","src":"icons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"purpose":"any","src":"icons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"purpose":"any","src":"icons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"purpose":"any","src":"icons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"purpose":"any","src":"icons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"purpose":"any","src":"icons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"purpose":"any","src":"icons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"9e82e3e0dbe558e44db6a00cf35487d8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-K6BKTFZMQV"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
