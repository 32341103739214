import { Settings } from '../../../config/Settings';
export class Api {
  HOST = Settings.KULTURIO_API_URL;
  DEFAULT_HEADERS = {
    "x-api-key": Settings.KULTURIO_API_KEY,
    "Content-Type": "application/json"
  }

  post = async (path, body) => {
    return await fetch(this.HOST + path, {
      method: "POST",
      headers: {
          ...this.DEFAULT_HEADERS
      },
      body: JSON.stringify(body)
    }).then(res => {
      return res.json();
    })
      .catch(err => {
        console.log("KiService error:", err);
      })
  }
  get = async path => {
    return await fetch(this.HOST + path, { 
        headers:{
            ...this.DEFAULT_HEADERS
        }
    }).then(res => {
      return res.json();
    })
      .catch(err => {
        console.log("KiService error:", err);
      })
  }

}
