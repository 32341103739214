
import React, { useContext, useEffect } from "react"
import "./src/fonts/fonts.css";
import "./src/styles/css_vars.css";
import { I18nextProvider } from 'react-i18next';
import { createGlobalStyle } from "styled-components";
import { KiService } from "./src/common/api/Kulturio/KiService";
import i18n from "./src/common/i18n/i18n"
import { Store, StoreProvider } from "./src/components/Store/store"
import {Settings} from "./src/config/Settings";
const defaultTheme = "light"
const APP_SETTINGS_API_UUID = Settings.TIDSROM_APP_SETTINGS_ID;
const GlobalStyle = createGlobalStyle`
    @supports (font: -apple-system-body) {
      html {
        font: -apple-system-body;
      } 
    }
    
    * {
      box-sizing: border-box;     
      font-family: "National", 'Franklin Gothic Demi', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;  
    }

    body {
      margin: 0;
      font-family: "National", 'Franklin Gothic Demi', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -webkit-tap-highlight-color: transparent;
      user-select: none;
    }

    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
    }
`;

const DataComponent = (props) => {
  const { state, dispatch } = useContext(Store);

  //const fn = () => {};
  //navigator.serviceWorker.register = () => new Promise(fn, fn);  // disable service worker

  useEffect(() => {
    if (!state) { return }
    const zoneUuids = state.constants.zoneUuids;
    const api = new KiService();

    Promise.all(zoneUuids.map(async (uuid) => {
      return api.getByUuid(uuid);
    })).then((tmp) => {

      dispatch({
        type: "SET_DATA_ZONES",
        payload: tmp
      })

    })
    if(!state.data.settings){return}
    if (Object.keys(state.data.settings).length) { return }
    api.getByUuid(APP_SETTINGS_API_UUID)
      .then((settings_res) => {
        //console.log("APP_SETTINGS_API_UUID res: ", settings_res);
        dispatch({
          type: "SET_DATA_SETTINGS",
          payload: settings_res
        })
      })

    

  }, []);

  useEffect(()=>{
    if(typeof document !== "undefined"){
      const root = document.querySelector(":root");
      if (state.data.largeFont){        
        root.style.fontSize = state.constants.largeFontRootSize;
      }else{
        root.style.fontSize = "16px";
      }
    }
  },[state.data.largeFont])

  return (<>{props.children}</>);
}



export const ThemeContext = React.createContext(defaultTheme)
export const wrapRootElement = ({ element }) => {

  return (
    <>
      <ThemeContext.Provider value={defaultTheme}>
        <GlobalStyle />

        <I18nextProvider i18n={i18n}>
          <StoreProvider>
            <DataComponent />
            {element}
          </StoreProvider>
        </I18nextProvider>

      </ThemeContext.Provider>
    </>
  )
}

export const registerServiceWorker = () => {
  if (typeof window !== 'undefined') {
    console.log(`
    \n
    ============================================================================================\n
    =                            Service worker requested                                      =\n
    ============================================================================================\n
    \n
    `);
    return true;
  }
  else {
    return false;
  }
}
